@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

body {
  margin: 0;
  background-color: black;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}
* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
